// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-connect-js": () => import("./../../../src/pages/connect.js" /* webpackChunkName: "component---src-pages-connect-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-trainers-js": () => import("./../../../src/pages/trainers.js" /* webpackChunkName: "component---src-pages-trainers-js" */),
  "component---src-pages-trainers-trainer-username-js": () => import("./../../../src/pages/trainers/{Trainer.username}.js" /* webpackChunkName: "component---src-pages-trainers-trainer-username-js" */),
  "component---src-pages-workout-[id]-js": () => import("./../../../src/pages/workout/[id].js" /* webpackChunkName: "component---src-pages-workout-[id]-js" */),
  "component---src-pages-workout-builder-js": () => import("./../../../src/pages/workout-builder.js" /* webpackChunkName: "component---src-pages-workout-builder-js" */),
  "component---src-pages-workouts-js": () => import("./../../../src/pages/workouts.js" /* webpackChunkName: "component---src-pages-workouts-js" */),
  "component---src-pages-workouts-workout-id-js": () => import("./../../../src/pages/workouts/{Workout.id}.js" /* webpackChunkName: "component---src-pages-workouts-workout-id-js" */)
}

